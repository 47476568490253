import React from 'react'
import Box from '@src/components/atoms/Box'
import { Layout } from '@src/components/layouts'

import '@src/theme/services.sass'

const downloadIcons = {
  android: require('@src/images/downloadIcons/google-play.svg'),
  ios: require('@src/images/downloadIcons/appstore.svg'),
}

const ServiceListItemApp = (props: any) => (
  <div className="ServiceListItemApp">
    <div className="ServiceAppLeft">
      <div className="ServiceAppLogo">
        <img src={props.icon} />
      </div>
    </div>
    <div className="ServiceAppRight">
      <h3 className="ServiceAppName">{props.name}</h3>
      <p
        className="ServiceAppDescription"
        dangerouslySetInnerHTML={{ __html: props.description }}
      />
      <div className="downloadIcons">
        {props.ios && (
          <a href={props.ios}>
            <img src={downloadIcons.ios} />
          </a>
        )}
        {props.android && (
          <a href={props.android}>
            <img src={downloadIcons.android} />
          </a>
        )}
      </div>
    </div>
  </div>
)

const ServiceList = () => (
  <div className="ServiceList">
    <div>
      <h2>Apps for phone</h2>
      <ServiceListItemApp
        icon={require('@src/images/PP1-logo.svg')}
        name="Podcast +1"
        description="A simple podcast audio player app, designed for users, who want to listen podcasts without tapping screen a lot of time.<br />Just open this app and tap an episode to play."
        ios="https://itunes.apple.com/us/app/podcast-1/id1358094409?ls=1&mt=8"
        android="https://play.google.com/store/apps/details?id=com.podcast_plus1"
      />
    </div>
    <div>
      <h2>Application Development</h2>
    </div>
    <div>
      <h2>Web Development &amp; Consulting</h2>
    </div>
  </div>
)

const ServicePage = ({  }: any) => (
  <Box>
    <h1 className="pageTitle">Our Services</h1>
    <ServiceList />
  </Box>
)
export default ServicePage
